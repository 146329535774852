import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  Container,
  Paper,
  makeStyles,
  Typography,
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import Email from '@material-ui/icons/Email';
import Facebook from '@material-ui/icons/Facebook';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Share from '@material-ui/icons/Share';
import Twitter from '@material-ui/icons/Twitter';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Close from '@material-ui/icons/Close';

import { BlogCard2, Layout, SEO } from '../components';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Button } from 'gatsby-theme-material-ui';

const useStyles = makeStyles((theme) => ({
  image: {
    height: '35vh',
    [theme.breakpoints.up('md')]: {
      height: '55vh',
    },
    width: '100%',
    zIndex: '-1',
    backgroundColor: grey[200],
  },
  postWrapper: {
    borderRadius: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: '-15vh',
      borderRadius: '4px',
      marginBottom: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: theme.typography.pxToRem(36),
    fontWeight: 'bold',
  },
  title2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  author: {
    fontFamily: 'Montserrat',
  },
  body: {
    paddingBottom: theme.spacing(1),
  },
  shareButtonWrapper: { marginRight: theme.spacing(2) },
  shareButton: {
    color: grey[800],
    fontSize: theme.typography.pxToRem(36),
    padding: '8px',
    borderRadius: '50%',
    transition:
      'transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), box-shadow 0.3s cubic-bezier(0.22, 1, 0.36, 1)',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        transform: 'translate(0, -2px)',
        boxShadow: `0 0 6px 1px rgba(0, 0, 0, 0.1)`,
      },
    },
  },
  // shareButtonClipboardWrapper: {
  //   height: theme.typography.pxToRem(36),
  //   width: theme.typography.pxToRem(36),
  //   borderRadius: '50%',
  //   padding: '8px',
  //   transition:
  //     'transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), box-shadow 0.3s cubic-bezier(0.22, 1, 0.36, 1)',
  //   [theme.breakpoints.up('sm')]: {
  //     '&:hover': {
  //       transform: 'translate(0, -2px)',
  //       boxShadow: `0 0 6px 1px rgba(0, 0, 0, 0.1)`,
  //     },
  //   },
  // },
}));

export default function BlogPost({ data, location }) {
  const classes = useStyles();
  const { site, markdownRemark } = data;
  const { frontmatter, html, excerpt } = markdownRemark;
  const image = frontmatter.image ? frontmatter.image.childImageSharp.resize : null;

  const [isCopied, setCopied] = useState(false);
  const handleCopy = () => {
    const el = document.createElement('textarea');
    el.value = site.siteMetadata.siteUrl + location.pathname;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <Layout location={location} navbarPosition="static">
      <SEO
        title={frontmatter.title}
        description={excerpt}
        image={image}
        pathname={location.pathname}
      />
      {frontmatter.show ? (
        <Img
          backgroundColor={grey[800]}
          fadeIn
          durationFadeIn={2000}
          fluid={frontmatter.image.childImageSharp.fluid}
          className={classes.image}
        />
      ) : (
        <div className={classes.image} />
      )}
      <Container maxWidth="md" className={classes.postWrapper}>
        <Paper className={classes.paper}>
          <div>
            <Typography
              variant="h5"
              component="h1"
              color="primary"
              className={classes.title}
            >
              {frontmatter.show && frontmatter.title}
            </Typography>
            <Typography variant="subtitle1" className={classes.author}>
              {frontmatter.show && frontmatter.author}
            </Typography>
            <Typography variant="subtitle2" className={classes.author}>
              {frontmatter.show && frontmatter.date}
            </Typography>
            <div>
              <Typography variant="body1" className={classes.body}>
                {frontmatter.show ? (
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                ) : (
                  'This post has been archived.'
                )}
              </Typography>
            </div>
          </div>
          {frontmatter.show && (
            <div>
              <div>
                <Divider component="hr" />
                <Grid container alignItems="center" justify="space-between" spacing={2}>
                  <Grid item xs={12} sm="auto">
                    <Typography variant="h5" color="primary" className={classes.title2}>
                      Share this post
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Box display="flex">
                      <EmailShareButton
                        url={site.siteMetadata.siteUrl + location.pathname}
                        subject={frontmatter.title + ' via pmhit.com'}
                        className={classes.shareButtonWrapper}
                      >
                        <Tooltip placement="top" title="Share via Email">
                          <Email className={classes.shareButton} />
                        </Tooltip>
                      </EmailShareButton>
                      <LinkedinShareButton
                        url={site.siteMetadata.siteUrl + location.pathname}
                        title={frontmatter.title}
                        summary={excerpt}
                        source={site.siteMetadata.siteUrl}
                        className={classes.shareButtonWrapper}
                      >
                        <Tooltip placement="top" title="Share on LinkedIn">
                          <LinkedIn className={classes.shareButton} />
                        </Tooltip>
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={site.siteMetadata.siteUrl + location.pathname}
                        title={frontmatter.title}
                        via={'ProjectHIT'}
                        hashtags={['pmhit', 'latest']}
                        related={[site.siteMetadata.social.twitter]}
                        className={classes.shareButtonWrapper}
                      >
                        <Tooltip placement="top" title="Share on Twitter">
                          <Twitter className={classes.shareButton} />
                        </Tooltip>
                      </TwitterShareButton>
                      <FacebookShareButton
                        url={site.siteMetadata.siteUrl + location.pathname}
                        quote={frontmatter.title + ' | ' + site.siteMetadata.title}
                        hashtag={'pmhit'}
                        className={classes.shareButtonWrapper}
                      >
                        <Tooltip placement="top" title="Share on Facebook">
                          <Facebook className={classes.shareButton} />
                        </Tooltip>
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={site.siteMetadata.siteUrl + location.pathname}
                        title={frontmatter.title}
                        className={classes.shareButtonWrapper}
                      >
                        <Tooltip placement="top" title="Share on WhatsApp">
                          <WhatsApp className={classes.shareButton} />
                        </Tooltip>
                      </WhatsappShareButton>
                      <Tooltip
                        placement="top"
                        title={isCopied ? 'Copied!' : 'Copy link to clipboard'}
                      >
                        <div className={classes.shareButtonWrapper}>
                          <Share className={classes.shareButton} onClick={handleCopy} />
                          {/* <FileCopy className={classes.shareButton} onClick={handleCopy} /> */}
                          {/* <SvgIcon className={classes.shareButton} onClick={handleCopy}>
                        <g>
                          <g id="content-copy">
                            <path
                              d="M395.25,0h-306c-28.05,0-51,22.95-51,51v357h51V51h306V0z M471.75,102h-280.5c-28.05,0-51,22.95-51,51v357
			c0,28.05,22.95,51,51,51h280.5c28.05,0,51-22.95,51-51V153C522.75,124.95,499.8,102,471.75,102z M471.75,510h-280.5V153h280.5V510
			z"
                            />
                          </g>
                        </g>
                      </SvgIcon> */}
                        </div>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <Divider component="hr" />
              {data.allMarkdownRemark.edges.length > 0 && (
                <div>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5" color="primary" className={classes.title2}>
                      Related
                    </Typography>
                    <Button
                      to="/blog"
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{
                        marginLeft: '14px',
                      }}
                    >
                      See All
                    </Button>
                  </Box>
                  <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    justify="space-evenly"
                    spacing={2}
                  >
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                      <Grid
                        key={node.fields.slug}
                        item
                        xs={12}
                        sm={data.allMarkdownRemark.edges.length < 3 ? 6 : 4}
                      >
                        <BlogCard2
                          title={node.frontmatter.title}
                          date={node.frontmatter.date}
                          path={`/blog${node.fields.slug}`}
                          image={node.frontmatter.image.childImageSharp.fluid}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </div>
          )}
        </Paper>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isCopied}
        autoHideDuration={4000}
        onClose={() => {
          setCopied(false);
        }}
        message="Copied to clipboard!"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setCopied(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        siteUrl
        social {
          twitter
        }
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      excerpt(pruneLength: 180)
      fields {
        slug
      }
      frontmatter {
        show
        date(formatString: "LL")
        title
        author
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 85) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
      }
      html
    }
    allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: { show: { eq: true }, type: { eq: "blog" } }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            author
            date(formatString: "LL")
            overview
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
